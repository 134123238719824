import * as React from "react"
import Header from "../components/layouts/Header"
import "../assets/css/global.scss";
import servicesBanner from "../assets/img/servies.svg";

import Banner from "../components/banner";
import Service from "../data/service.json";
import Layouts from "../components/layouts";

import Aos from "aos";
import "aos/dist/aos.css";

import ServiceBody from "../components/ServiceBody";

// markup
const IndexPage = () => {
  return (
    <>
      <Layouts>

        <Banner bannerVector={servicesBanner}>
          <h2>Services</h2>
          <p className="banner-para">Our Digital Service Offerings  <span>Accelerate your transformation journey with our comprehensive service offerings</span></p>
          
        </Banner>

        <div>

          <main>
            <div className="page-section-top">
              <div className="container">
                <div className="row" animation="fade-up">
                  <div className="col-lg-6 ">
                    <h2 className="title-section">We're <span className="marked">ready to</span> Serve you with best</h2>
                    <div className="divider" />
                    <p className="">We identify ourselves as a digital solution provider and we offer consultative engagement with a focus on digital transformation built on Social/Mobile/Data/Cloud solution architecture. We work across technology platforms and frameworks for our solution offerings and we follow Agile development methodology with iterative releases. Our development process is tool driven using Pivotal Tracker/InvisionApp/Slack/CI & CD/GitHub for enhanced transparency in execution.</p>
                  </div>
                  <div className="col-lg-6">
                    <div className="img-place text-center">
                      <img src="../img/server-maintains.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Service.map((elem, key) => (
              <div className="" key={key}>
                <div className="container">
                  <ServiceBody title={elem.title} serviceImage={elem} imgAlign={elem.align} animation="fade-up" >
                    <p className="mb-5">{elem.content}</p>
                  </ServiceBody> 
                </div>
              </div>
            ))}
            {/* <div className="page-section">
              <div className="container">
                <ServiceBody title="Mobile Application" serviceImage={serviceImage.mobile} imgAlign="right" animation="fade-up">
                  <p className="mb-5">We provide marketing services to startups &amp; small business to looking for partner for their
                    digital media, design &amp; dev lead generation &amp; communication.</p>
                </ServiceBody>
              </div>
            </div>
            <div className="page-section">
              <div className="container">
                <ServiceBody title="Devops & Automation" serviceImage={serviceImage.devops} imgAlign="left" animation="fade-up">
                  <p className="mb-5">We provide marketing services to startups &amp; small business to looking for partner for their
                    digital media, design &amp; dev lead generation &amp; communication.</p>
                </ServiceBody>
              </div>
            </div> */}
          </main>
        </div>
      </Layouts>
    </>
  )
}

export default IndexPage
