import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ServiceBody({ title, serviceImage, children, imgAlign, animation}) {
    
    useEffect(function () {
        Aos.init({ duration: 1500 });
      }, []);

    return (
        <>
            <div className="row page-section" data-aos={animation}>
                {imgAlign=="left" && <div className="col-lg-6">
                    <div className=" text-center">
                        <img src={serviceImage.image} alt="" className="card-img " />
                    </div>
                </div>}
                <div className="col-lg-6  left-right">
                    <h2 className="title-section">{title}</h2>
                    <div className="divider" />
                    {children}
                   <div className="services-icon">
                        {serviceImage.icons.map((elem, key) => (
                            <img src={elem.icon} alt="" className="card-icons" key={key} />
                        ))}
                    </div>
                </div>

                {imgAlign=="right" && <div className="col-lg-6 ">
                    <div className=" text-center">
                        <img src={serviceImage.image} alt="" className="card-img " />
                    </div>
                </div>}
            </div>
        </>
    )
}